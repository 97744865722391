import Rails from "@rails/ujs"

$(document).on "turbolinks:load", ->
  FontAwesome.dom.i2svg()

  $("time.timeago").timeago()

  $('body').tooltip
    selector: '[data-toggle="tooltip"]'
    html: true

  # $('.infinite-scroll').infiniteScroll
  #   path: '.js-endless-scroll'
  #   append: '.js-append-infinite'
  #   status: '.scroller-status'
  #   hideNav: '.js-pagination'
  #   # debug: true
  #   prefill: true
  #   responseType: 'document'

  # $('.infinite-scroll').on 'append.infiniteScroll', (event, response, path, items)->
  #   $("time.timeago").timeago()
  #   FontAwesome.dom.i2svg()

  $('a[data-toggle="tab"]').on 'show.bs.tab', (e)->
    tab = $(e.target.getAttribute('href') + ' .js-settings.loading')
    if tab.length == 1
      $.getScript(tab.data('url'))

  $(document).on 'click','a[data-toggle="children"]', ->
    target = $(this).data('target')
    $(this).children().toggle()

  $(document).on 'change', 'input[type=file][data-direct-upload-url]', ->
    Rails.fire(this.form, 'submit')

  $(document).on 'hidden.bs.toast', '.toast', ->
    $(this).remove()
