import $ from 'jquery'

import './index.scss'

// List groups as multiple select.
//
// This minimalist plugin enable list groups formed with labels and hidden checkboxes, to be used as
// a descriptive multiple select.
//
// Given the following markup:
//
//     <div class="list-group">
//       <label class="list-group-item list-group-item-action" data-toggle="active">
//         <h5>Role Manager</h5>
//         <p>This role gives capabilities of <b>roles</b> management, this should be given to very few people<p>
//         <input class="form-check-input" type="checkbox" class="d-none">
//       </label>
//     </div>
//
// As default browser behavior, clicking on label will change checkbox state, this plugins listens
// to this change event and toggle the give class via `data-toggle` accordingly.
$(document).on("turbolinks:load", function() {
  $('label[data-toggle] > input').on('change', function() {
    let $input = $(this);
    let $label = $input.parent('label');

    $label.toggleClass($label.data('toggle'), $input.prop('checked'))
  })

  $('label[data-toggle] > input:checked').trigger('change');
})
