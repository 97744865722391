addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  inputContainer = $(target).parents('.form-group')
  height = inputContainer.height()
  inputContainer.toggleClass('d-none')
  inputContainer.before(`
    <div id="direct-upload-progress-${id}" class="progress d-none" style="height: ${height}px;">
      <div id="direct-upload-progress-bar-${id}" aria-valuemax="100" aria-valuemin="0" aria-valuenow="0" class="progress-bar" role="progressbar"></div>
    </div>
  `)
  if ($(target).data('direct-upload-preview')) {
    var preview = document.querySelector('img.js-direct_upload_preview');
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
      preview.src = reader.result;
    }, false);
    reader.readAsDataURL(file);
  }
  
})
 
addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-progress-${id}`)
  element.classList.remove("d-none")
  
})
 
addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-bar-${id}`)
  progressElement.style.width = `${progress}%`
  progressElement.innerHTML = `${Math.round(progress)}%`
})
 
addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-progress-${id}`)
  element.classList.add("bg-danger")
  element.classList.remove("d-none")
  element.setAttribute("title", error)
  $(element).html('<div class="text-center w-100 pt-2 h5">Failed</div>')
  const purge = document.querySelector('.js-direct_upload_purge')
  purge.classList.add("d-none")
  var preview = document.querySelector('img.js-direct_upload_preview');
  preview.classList.add("img-error")
})
 
addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-bar-${id}`)
  progressElement.classList.add("bg-success")
  const element = document.getElementById(`direct-upload-progress-${id}`)
  element.classList.add("d-none")
  const purge = document.querySelector('.js-direct_upload_purge')
  purge.classList.remove("d-none")
})
