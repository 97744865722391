import Rails from '@rails/ujs'
Rails.start();

require('turbolinks').start()
require('@rails/activestorage').start()
require('@rails/actiontext')
require('channels')

import 'trix';
import 'bootstrap/dist/js/bootstrap';
import '@coreui/coreui';

import $ from 'jquery';

import '../src/js/vendor/fontawesome-all';
import '../src/js/vendor/jquery.timeago';
import '../src/js/vendor/rails.direct_uploads';
import '../src/js/vendor/bootstrap-auto-dismiss-alert';
import '../src/js/are-you-sure';
import '../src/js/initialization';
import '../src/js/settings';

import '../components/list-group-multi-select';

import '../src/scss/application';
