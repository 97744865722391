import Rails from "@rails/ujs";
import $ from 'jquery';
import 'bootstrap/js/src/modal.js';

// Overrides Rails.confirm alert to use Bootstrap modal.
//
// It creates a bootstrap modal on the fly, that will contain the
// `data-confirm` text in the modal body. There are too modal actions:
//
// 1. Continue: proceed with the interruped action by delegating triggering
//             `click` to evoke the action (overriding the confirm fn);
// 2. Dismiss: does nothing but hides and deletes the modal;
//
Rails.confirm = function(message, element) {
  let $element = $(element);
  let $dialog = $(
    '<div class="modal fade">' +
    '  <div class="modal-dialog modal-dialog-centered">' +
    '    <div class="modal-content">' +
    '      <div class="modal-header">' +
    '        <h4 class="modal-title">Are you sure?</h4>' +
    '        <button type="button" class="close" data-dismiss="modal">&times;</button>' +
    '      </div>' +
    '      <div class="modal-body">' +
             message +
    '      </div>' +
    '      <div class="modal-footer">' +
    '        <button type="button" class="btn btn-secondary" data-dismiss="modal">Dismiss</button>' +
    '        <button type="button" class="btn btn-primary" data-proceed="true">Continue</button>' +
    '      </div>' +
    '    </div>' +
    '  </div>' +
    '</div>'
  );

  $dialog.find('[data-proceed="true"]').click(function(event) {
    event.preventDefault();

    $dialog.modal('hide');
    $dialog.remove();

    let old = Rails.confirm

    Rails.confirm = function() { return true }

    $element.get(0).click();
    Rails.confirm = old;
  })

  $dialog.on('hidden.bs.modal', function(event) {
    $dialog.modal('dispose');
    $dialog.remove();
  })

  $dialog.modal('show');

  return false;
}
